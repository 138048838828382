<template>
    <div class="my-4 pa-4">
        <v-form v-model="valid" ref="form" class="py-4">

        <v-row v-if="editMode">
            <v-col cols="12" md="3">
                <p class="colTitle mb-0">{{ $t("Attendance Comments Type") }}</p>
            </v-col>
            <v-col cols="12" md="6">
                <v-row>
                    <v-select item-text="name"
                  item-value="id" :class="currentAppLocale == 'en' ? 'dir-left' : 'dir-right' " :items="selectedItems"
                     v-model.trim="selectedType" solo></v-select>
                </v-row>
            </v-col>
        </v-row>

        <v-row v-if="!editMode">
            <v-col cols="12" md="3">
                <p class="colTitle mb-0">{{ $t("Attendance Comments Type") }}</p>
            </v-col>
            <v-col cols="12" md="6">
              
                    <p class="colTitle">{{ $t(selectedType) }}</p>
             
            </v-col>
            <v-col cols="12" md="3">
                <v-icon class="main-color" @click="editMode = true" large :title="$t('Edit')">edit
                </v-icon>
            </v-col>
        </v-row>

        <v-row v-if="selectedType == 'presetList'">
            <v-row v-if="editMode">
                <v-col :class="currentAppLocale == 'en' ? 'text-right' : 'text-align-left'">
                    <v-icon class="main-color" @click="addOption()"  large :title="$t('Add New')">add_circle
                    </v-icon>
                </v-col>
            </v-row>
            <v-col cols="12" sm="12" md="12" v-if="selectedType == 'presetList'">
            <div class="box-options">
                <v-row v-for="(item, index) in listOptions">
                    <v-col cols="5" sm="5" md="5">
                        <label class="colTitle" style="color: #444;">{{ $t("English Option") }} </label>
                        
                        <v-text-field v-if="editMode" v-model.trim="item.en_name" :rules="[validationRules.required]"  solo></v-text-field>
                        <p class="colTitle" v-if="!editMode">{{ item.en_name }}</p>
                       
                    </v-col>
                    <v-col cols="5" sm="5" md="5">
                        <label class="colTitle" style="color: #444;">{{ $t("Arabic Option") }} </label>
                        <v-text-field v-if="editMode" v-model.trim="item.ar_name" :rules="[validationRules.required, CheckArabicCharactersOnly(item.ar_name)]" solo></v-text-field>
                        <p class="colTitle" v-if="!editMode">{{ item.ar_name }}</p>
                    
                    </v-col>
                    <v-col cols="2" sm="2" md="2" v-if="editMode">
                        <!-- array.splice(index, 1) -->
                        <v-icon class="red-color" style="margin-top: 35px;" @click="openDeleteDial(index)" :title="$t('Remove Option')">delete
                        </v-icon>
                    </v-col>
                </v-row>
            </div>
            </v-col>

        </v-row>
        <v-row v-if="editMode">
            <v-col cols="4" sm="4" md="4"></v-col>
            <v-col cols="8" sm="8" md="8">
                <div :class="currentAppLocale == 'en' ? 'text-right mt-3' : 'text-align-left mt-3'">
                    <v-btn class="modal-btn-cancel cancelBtn btn-defalut" style="margin-left: 8px;margin-right: 8px;" 
                    @click="closeDia">{{
                    $t("Cancel")
                    }}</v-btn>
                    <v-btn class="modal-btn-save" @click="save" :loading="loading" :disabled="loading">{{ $t("Save") }}</v-btn>
                    
                </div>
                
               
            </v-col>
        </v-row>
        
        </v-form>

       

    <snackComponent :snackbar="snackbar" :snackColor="snackColor" :snackMsg="snackMsg"
            @closeSnack="snackbar = false"></snackComponent>


            <v-dialog v-model="dialogReset" persistent max-width="320" class="dialog_confirm">
                <v-card>
                    <v-card-title class="headline">{{
                    $t("Confirmation Message")
                    }}</v-card-title>
            
                    <v-card-text>{{
                    $t("Are you sure you want to Delete the option ?")
                    }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
            
                        <v-btn class="modal-btn-cancel noBtn" @click="dialogReset = false" id="noBtn" ref="always_focus">{{ $t("No")
                        }}</v-btn>
                        <v-btn id="yesBtn" @click="removeOption(optionIndex)" class="modal-btn-save yesBtn">{{ $t("Yes") }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </div>
</template>

<script>
import snackComponent from "@/components/snackComponent";
import { validationMixin } from "../../mixins/validationMixin";

export default {
    components: {
        snackComponent,
    },
    mixins: [validationMixin],
    data() {
        return {
            dialogReset:false,
            editMode:false,
            valid: false,
            optionIndex:0,
            snackbar: false,
            snackColor: "",
            snackMsg: "",
            type: "add",
            confirmDeletedialog: false,
            deleteConfirmationMsg: this.$i18n.t(
                "Are you sure you want to delete this attachement ?"
            ),
            selectedItems: [{ id: 'textbox', name: this.$i18n.t("Textbox") }, { id: 'presetList', name: this.$i18n.t("Preset List") }],
            selectedType: "",
            openDialog: false,
            selectedItem: {},
            selected_id: "",
            loading: false,
            circle: true,
            page: 1,

            length: 1,
            totalVisible: "",
            listOptions:[],
            items: [],
        };
    },
    watch: {
        selectedType: {
            handler() {
                if (this.selectedType == 'presetList' && this.listOptions.length < 1){
                    this.listOptions.push({ 'ar_name': "", 'en_name': "" });
                }
            },
            deep: true,
        },
    },
    methods: {
        openDeleteDial(index){
            this.optionIndex = index;
            this.dialogReset = true;
        },
        closeDia(){
            this.dialogReset = false;
            this.optionIndex = 0;
            this.editMode= false;
            this.getAttendanceConfiguration();
        },
        addOption(){
            this.listOptions.push({ 'ar_name': "", 'en_name': "" });
        },
        removeOption(index){
            if(this.listOptions.length <= 1){
             
                    this.snackbar = true;
                    this.snackColor = "error";
                    this.snackMsg = this.$i18n.t("The last value cannot be removed");
              
                setTimeout(() => {
                    this.snackbar = false;
                }, 2000);
            }else{
                this.listOptions.splice(index, 1);
                this.dialogReset = false; 
            }
            
        },
       
        getAttendanceConfiguration() {
            this.loading = true;
            this.$http
                .get(this.getApiUrl + "/school/attendance-comment-configuration", {
                    headers: {
                        Authorization: "Bearer " + localStorage.token,
                    },
                })
                .then((response) => {
                    this.loading = false;
                    if (response.data.status.error == false) {
                        // console.log(response.data.data)
                        this.selectedType = response.data.data.selectedType;
                        this.listOptions = response.data.data.list;
                       
                    }
                });
        },
       save(){
        //    attendance - comment - configuration
           if (!this.valid) {
               this.$refs.form.validate();
           } else {
               if (this.selectedType == 'presetList' && this.listOptions.length <=0){
                  
                  
                    this.snackbar = true;
                    this.snackColor = 'error'
                    this.snackMsg = this.$i18n.t("shoud Adding Option To List");
                   setTimeout(() => {
                       this.snackbar = false;
                   }, 3000);
                   
               }else{
                   this.loading = true;
                   var data = {
                       selectedType: this.selectedType,
                       list: this.listOptions
                   };
                   this.$http
                       .post(
                           this.getApiUrl + "/school/attendance-comment-configuration",
                           data,
                           {
                               headers: {
                                   Authorization: "Bearer " + localStorage.token
                               }
                           }
                       )
                       .then(response => {
                           if (response.data.status.error == false) {
                                this.snackbar = true;
                                this.snackColor = "green";
                                this.snackMsg = this.$i18n.t("Success");
                               setTimeout(() => {
                                   this.snackbar = false;
                               }, 3000);
                               this.editMode= false,
                               this.getAttendanceConfiguration();
                           }
                           
                           this.loading = false;
                       });
               }
               

           }
       }
    },
    mounted() {
        this.getAttendanceConfiguration();
    },
};
</script>

<style>
.dir-right{
    direction: rtl !important;
}
.dir-left{
    direction: ltr !important;
}
.text-right{text-align: right;}
.text-align-left{text-align: left;}
</style>
