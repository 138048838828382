<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5 main-color" v-if="type == 'add'">
          {{ $t("Add Warehouse") }}
        </v-card-title>
        <v-card-title class="text-h5 main-color" v-else>
          {{ $t("Edit Warehouse") }}
        </v-card-title>
        <v-card-text class="my-4">
          <!-- Edit Mode -->
          <v-form
            ref="form"
            v-model="valid"
            :class="currentAppLocale == 'en' ? '' : 'text-right'"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="items" style="padding: 0 20px">
                  <!-- Code -->
                  <div class="item-form form-group">
                    <label>{{ $t("Code") }}</label>
                    <v-text-field
                      v-model="warehouse.code"
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength50,
                      ]"
                      solo
                    ></v-text-field>

                    <div
                      v-if="validation_errors.warehouse.code"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-for="codeError in validation_errors.warehouse.code"
                      >
                        {{ codeError }}
                      </p>
                    </div>
                  </div>

                  <!-- Name -->
                  <div class="item-form form-group">
                    <label>{{ $t("Name") }}</label>
                    <v-text-field
                      v-model="warehouse.name"
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength100,
                      ]"
                      solo
                    ></v-text-field>

                    <div
                      v-if="validation_errors.warehouse.name"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.warehouse.name &&
                          validation_errors.warehouse.name > 0
                        "
                      >
                        {{ validation_errors.warehouse.name }}
                      </p>
                    </div>
                  </div>

                  <!-- short Name -->
                  <div class="item-form form-group">
                    <label>{{ $t("Short Name") }}</label>
                    <v-text-field
                      v-model="warehouse.short_name"
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength10,
                      ]"
                      solo
                    ></v-text-field>

                    <div
                      v-if="validation_errors.warehouse.short_name"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.warehouse.short_name &&
                          validation_errors.warehouse.short_name > 0
                        "
                      >
                        {{ validation_errors.warehouse.short_name }}
                      </p>
                    </div>
                  </div>
                  <!-- item categories -->
                  <div class="item-form form-group">
                    <label>{{ $t("Item Categories") }}</label>
                    <v-autocomplete
                      v-model="warehouse.items_categories"
                      :items="allCategories"
                      item-text="name"
                      item-value="id"
                      chips
                      label
                      multiple
                      solo
                    ></v-autocomplete>
                  </div>
                </div>
              </v-col>
              <!-- Assignee -->
              <v-col cols="12" sm="12" md="12">
                <div class="items" style="padding: 0 20px">
                  <label>{{ $t("Assignees") }}</label>
                  <v-autocomplete
                    v-model="warehouse.assignees"
                    :items="allUsers"
                    item-text="name"
                    item-value="id"
                    chips
                    label
                    multiple
                    solo
                  ></v-autocomplete>

                  <!--
                    @change="pushInAssigneeNames"
                     <label>{{ $t("Assignee(s)") }}</label>
                  <div class="item-form form-group">
                    <v-chip-group
                      column
                      active-class="primary--text"
                      v-model="warehouse.assignees"
                    >
                      <div
                        v-for="(user, index) in warehouse.assignees_names"
                        :key="index"
                      >
                        <v-chip
                          class="ma-2"
                          color="#E1E9FF"
                          text-color="#5A86FF"
                          close
                          @click:close="deleteAssignee(index)"
                          >{{ user.name }}</v-chip
                        >
                      </div>
                    </v-chip-group>
                  </div> -->
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="modal-btn-cancel" @click="closeDialog">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn class="modal-btn-save" @click="save()">
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "addEditWarehouse",
  mixins: [validationMixin],

  props: ["editedItem", "dialog", "allCategories", "allUsers", "type"],

  data() {
    return {
      valid: false,
      validation_errors: {
        api_key: "",
        api_secret_key: "",
        jwt_token: "",
        account_username: "",

        sms: {
          configration: [],
        },
        email: {
          configration: [],
        },
        warehouse: {
          id: "",
          code: [],
          name: "",
          short_name: "",
        },
      },
      warehouse: {
        name: "",
        short_name: "",
        code: "",
        assignees: [],
        assignees_names: [],
        items_categories: [],
      },
    };
  },
  // watch: {
  //   editedItem: {
  //     handler() {
  //       this.warehouse = this.editedItem;
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    closeDialog(afterSave = false) {
      this.$emit("closeDialog", afterSave);
    },
    save() {
      // storeWarehouses
      if (this.valid) {
        this.$http
          .post(
            this.getApiUrl + "/warehouses/storeWarehouses/",
            this.warehouse,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              console.log(response);
              this.closeDialog(true);
            } else if (response.data.status.error == true){
              this.validation_errors.warehouse = response.data.status.validation_errors;
            }
          });

        // this.closeAddEditDialog();
      } else {
        this.validate();
      }
    },
  },
  mounted() {
    this.warehouse = { ...this.editedItem };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/_modals.scss";
</style>
