<template>
  <div class="my-8 warehouses">
    <v-row>
      <v-col>
        <h2 class="main-color">{{ $t("All Warehouses") }}</h2>
      </v-col>
      <v-col :class="currentAppLocale == 'en' ? 'text-right' : 'text-align-left'">
        <v-btn icon @click="openAddEditDialog({}, 'add')">
          <v-icon class="main-color" large :title="$t('Add New')"
            >add_circle</v-icon
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :options.sync="options"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      item-key="id"
      :show-select="false"
      class="elevation-1 level1 templates text-center"
      text="left"
      hide-default-footer
    >
      <template v-slot:item.assignees_names="{ item }">
        <p class="mb-0">
          <span
            v-for="(nameobj, index) in item.assignees_names"
            :key="nameobj.id"
            >{{ nameobj.name }}
            <span
              v-if="
                item.assignees_names && index < item.assignees_names.length - 1
              "
            >
              -
            </span>
          </span>
        </p>
      </template>
      <template v-slot:item.items_categories="{ item }">
        <p class="mb-0">
          <span v-for="(id, index) in item.items_categories" :key="id"
            >{{ allCategories.find((cat) => cat.id == id).name }}
            <span
              v-if="
                item.items_categories &&
                index < item.items_categories.length - 1
              "
            >
              -
            </span>
          </span>
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="openAddEditDialog(item, 'edit')">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon @click="openConfirmDeleteDialog(item.id)">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center my-2">
      <v-pagination
        v-if="length > 0"
        v-model="page"
        :length="length"
        :total-visible="totalVisible"
        circle
      ></v-pagination>
    </div>

    <addEditWarehouse
      :dialog="dialog"
      v-if="dialog"
      :editedItem="editedItem"
      :allCategories="allCategories"
      :allUsers="allUsers"
      :type="type"
      @closeDialog="closeDialog"
    ></addEditWarehouse>

    <general-confirm-delete-dialog
      :msg="deleteConfirmationMsg"
      :item_id="selected_id"
      :confirmDeletedialog="confirmDeletedialog"
      @closeConfirmDeleteDialog="confirmDeletedialog = false"
      @deleteItem="deleteWarehouse"
    ></general-confirm-delete-dialog>

    <snackComponent
      :snackbar="snackbar"
      :snackColor="snackColor"
      :snackMsg="snackMsg"
      @closeSnack="snackbar = false"
    ></snackComponent>
  </div>
</template>

<script>
import addEditWarehouse from "@/components/warehouses/addEditWarehouse.vue";
import GeneralConfirmDeleteDialog from "../generalConfirmDeleteDialog.vue";
import snackComponent from "@/components/snackComponent";
export default {
  name: "multiWareHousesConfig",
  components: { addEditWarehouse, GeneralConfirmDeleteDialog, snackComponent },
  data() {
    return {
      snackbar: false,
      snackColor: "green",
      snackMsg: "",
      dialog: false,
      deleteConfirmationMsg: this.$i18n.t(
        "Are you sure you want to delete this warehouse ?"
      ),
      selected_id: "",
      confirmDeletedialog: false,
      type: "",
      loading: false,
      options: {
        itemsPerPage: 15,
      },

      circle: true,
      page: 1,
      length: 1,
      totalVisible: 5,
      headers: [
        {
          text: this.$i18n.t("Name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: this.$i18n.t("Short Name"), value: "short_name" },
        { text: this.$i18n.t("Code"), value: "code" },
        { text: this.$i18n.t("Assignees"), value: "assignees_names" },
        { text: this.$i18n.t("Item Categories"), value: "items_categories" },
        { text: this.$i18n.t("Actions"), value: "actions" },
      ],
      items: [],
      editedItem: {},
      allCategories: [],
      allUsers: [],
    };
  },
  watch: {
    page: function () {
      this.getAllWarehouses();
    },
  },
  methods: {
    openConfirmDeleteDialog(id) {
      this.confirmDeletedialog = true;
      this.selected_id = id;
    },
    deleteWarehouse(id) {
      this.$http
        .get(this.getApiUrl + "/warehouses/deleteWarehouse/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.confirmDeletedialog = false;
            this.getAllWarehouses();
            this.snackbar = true;
            this.snackMsg = this.$i18n.t("Attachment Deleted Successfully");
            setTimeout(() => {
              this.snackbar = false;
            }, 2000);
          }
        });
    },
    getAllUsers() {
      this.$http
        .get(this.getApiUrl + "/warehouses/getUsers", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.allUsers = response.data.data;
        });
    },
    getAllCategories() {
      this.$http
        .get(this.getApiUrl + "/warehouses/getItems", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.allCategories = response.data.data;
        });
    },
    getAllWarehouses() {
      this.$http
        .get(this.getApiUrl + "/warehouses/getWarehouses?page=" + this.page, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.items = response.data.data.data;
          this.page = response.data.data.current_page;
          this.options.itemsPerPage = response.data.data.per_page;
          this.length = response.data.data.last_page;
        });
    },
    openAddEditDialog(item = {}, type) {
      this.dialog = true;
      this.type = type;
      if (type == "edit") {
        this.editedItem = item;
      } else {
        this.editedItem = {
          name: "",
          short_name: "",
          code: "",
          assignees: [],
          assignees_names: [],
          items_categories: [],
        };
      }
    },
    closeDialog(afterSave) {
      this.dialog = false;
      if (afterSave) {
        this.getAllWarehouses();
      }
    },
  },
  mounted() {
    this.getAllCategories();
    this.getAllUsers();
    this.getAllWarehouses();
  },
};
</script>

<style></style>
