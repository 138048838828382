<template>
  <div id="workingDays">
    <!-- <v-row>
      <v-col cols="12">
        <div class="title">
          <h1>Working Days Configuration</h1>
        </div>
      </v-col>
    </v-row>-->
    <v-row>
      <v-col cols="12" md="12" class="mt-4" style="padding-left: 0 !important">
        <v-tabs v-model="tab" class="tabs" background-color="#ffffff" dark>
          <v-tab @click="routeTab(0)" v-show="viewPushNotifcation">{{
            $t("Push Notifications")
          }}</v-tab>
          <v-tab @click="routeTab(1)" v-show="configureVacations">{{
            $t("Working Days Configuration")
          }}</v-tab>
          <v-tab @click="routeTab(2)" v-show="configureVacations">{{
            $t("Zoom Configuration")
          }}</v-tab>
          <!-- sms stmp configration -->
          <v-tab @click="routeTab(3)" v-show="configureVacations">{{
            $t("Email and SMS")
          }}</v-tab>
          <!-- Warehouse -->
          <v-tab v-show="viewWarwhouse" @click="routeTab(4)">{{
            $t("Warehouses")
          }}</v-tab>

          <!-- Announcement -->
          <v-tab v-show="configureVacations" @click="routeTab(5)">{{
            $t("Announcements")
          }}</v-tab>
          <v-tab v-show="configureVacations" @click="routeTab(6)">{{
            $t("Students Documents")
          }}</v-tab>
          <v-tab @click="routeTab(7)" v-show="attendance">{{
          $t("Attendance Note")
          }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-card class="pt-3">
      <v-tabs-items v-model="tab" touchless>
        <!-- Push Notifications -->
        <v-tab-item>
          <v-card flat>
            <v-card-text v-if="viewPushNotifcation">
              <!-- <v-row>
                <v-col cols="12" md="11">
                  <p class="colTitle">Push Notifications aaaa</p>
                </v-col>
                <v-col cols="1" md="1">
                  <v-icon
                    class="main-color"
                    large
                    @click="createPushNotification"
                    v-can="'add-push-notifications'"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row> -->
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headersNotifcations"
                :items="notificationsItems"
                item-key="id"
                :items-per-page="10"
                :show-select="false"
                class="elevation-1 level1 templates"
                text="left"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-row class="headerTable-div">
                    <v-col md="4" sm="12">
                      <!-- <h3 class="custom-header main-color">
                        Notification Events
                      </h3> -->
                      <p class="value filter-p" @click="filter.date = 'past'">
                        {{ $t("Past") }}
                      </p>
                      <p
                        class="value filter-p"
                        @click="filter.date = 'upcomming'"
                      >
                        {{ $t("Upcoming") }}
                      </p>
                      <p
                        class="value filter-p main-color"
                        @click="filter.date = 'all'"
                      >
                        {{ $t("All") }}
                      </p>
                    </v-col>
                    <!-- search and Filtes -->
                    <v-col md="4" sm="12">
                      <label class="search" for="inpt_search">
                        <input
                          id="inpt_search"
                          class="input_search"
                          @focus="inputFocus"
                          @blur="inputBlur"
                          type="text"
                          v-model="search"
                        />
                      </label>
                      <span title="Filter">
                        <v-menu
                          v-model="filterMenu"
                          offset-y
                          :close-on-content-click="false"
                          :nudge-width="300"
                          offset-x
                        >
                          <template v-slot:activator="{ on }">
                            <i
                              :title="$t('Filter')"
                              v-on="on"
                              class="fas fa-filter main-color fa-lg"
                            ></i>
                          </template>
                          <v-list>
                            <v-list-item class="select">
                              <v-autocomplete
                                :items="nationalites"
                                item-text="name"
                                item-value="id"
                                :placeholder="$t('Nationalites')"
                                solo
                                multiple
                                v-model="filter.nationalites"
                              >
                              </v-autocomplete>
                            </v-list-item>

                            <v-list-item class="select">
                              <v-autocomplete
                                :items="audiences"
                                item-text="name"
                                item-value="id"
                                :placeholder="$t('Audiences')"
                                solo
                                multiple
                                v-model="filter.audiences"
                              >
                              </v-autocomplete>
                            </v-list-item>

                            <v-list-item class="select">
                              <v-autocomplete
                                :items="plateform"
                                :placeholder="$t('Platform')"
                                solo
                                multiple
                                v-model="filter.plateform"
                              >
                              </v-autocomplete>
                            </v-list-item>

                            <v-card-actions> </v-card-actions>
                          </v-list>
                        </v-menu>
                      </span>
                    </v-col>

                    <v-col md="3" sm="12" class="text-right">
                      <v-icon
                        class="main-color"
                        :title="$t('Add New')"
                        large
                        @click="createPushNotification"
                        v-can="'add-push-notifications'"
                        >add_circle</v-icon
                      >

                      <!-- ================== Activity logs ================== -->
                      <v-btn
                        icon
                        to="/systemlogging/push_notification"
                        v-if="activitylogPermission"
                        target="_blank"
                        class="mt-2"
                      >
                        <v-icon
                          class="main-color"
                          large
                          :title="$t('System Log')"
                          >mdi-archive-clock</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item.subject="{ item }">
                  <v-tooltip bottom max-width="1200px">
                    <template v-slot:activator="{ on }">
                      <p dark v-on="on">
                        <router-link :to="'/viewPushNotification/' + item.id">{{
                          item.subject ? item.subject.slice(0, 15) : ""
                        }}</router-link>
                      </p>
                    </template>
                    <span>{{ item.subject }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.message="{ item }">
                  <v-tooltip bottom max-width="1200px">
                    <template v-slot:activator="{ on }">
                      <p dark v-on="on">
                        {{ item.message ? item.message.slice(0, 15) : "" }}
                      </p>
                    </template>
                    <span>{{ item.message }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.audience="{ item }">
                  <v-tooltip bottom max-width="1200px">
                    <template v-slot:activator="{ on }">
                      <p dark v-on="on">
                        {{ item.audience ? item.audience.slice(0, 15) : "" }}
                      </p>
                    </template>
                    <span>{{ item.audience }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.nationality="{ item }">
                  <v-tooltip bottom max-width="1200px">
                    <template v-slot:activator="{ on }">
                      <p dark v-on="on">
                        {{
                          item.nationality ? item.nationality.slice(0, 15) : ""
                        }}
                      </p>
                    </template>
                    <span>{{ item.nationality }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.time="{ item }">
                  <v-tooltip bottom max-width="1200px">
                    <template v-slot:activator="{ on }">
                      <p dark v-on="on">
                        {{ item.time ? item.time.slice(0, 15) : "" }}
                      </p>
                    </template>
                    <span>{{ item.time }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.linked_to="{ item }">
                  <v-tooltip bottom max-width="1200px">
                    <template v-slot:activator="{ on }">
                      <p dark v-on="on">
                        <a target="_blank" :href="item.linked_to">{{
                          item.linked_to ? item.linked_to.slice(0, 15) : ""
                        }}</a>
                      </p>
                    </template>
                    <span>{{ item.linked_to }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon
                    :title="$t('Edit')"
                    class="main-color"
                    :id="item.id"
                    v-if="!item.is_sent"
                    @click="editNotifcation(item)"
                    >edit</v-icon
                  >

                  <v-icon
                    class="main-color"
                    :id="item.id"
                    @click="openDia(item)"
                    :title="$t('Delete')"
                    >delete</v-icon
                  >
                </template>
              </v-data-table>
              <!-- ======================================== Pagination ======================================  -->
              <div class="text-center pt-2">
                <v-pagination
                  v-if="length > 0"
                  v-model="page"
                  :length="length"
                  :circle="circle"
                  :page="page"
                  :total-visible="totalVisible"
                  next-icon="mdi-menu-right"
                  prev-icon="mdi-menu-left"
                ></v-pagination>
              </div>
            </v-card-text>
            <v-card-text v-else>
              <v-row class="headerTable-div">
                <v-col md="4" sm="12"> </v-col>
                <!-- search and Filtes -->
                <v-col md="4" sm="12"> </v-col>

                <v-col md="3" sm="12" class="text-right">
                  <v-icon
                    :title="$t('Add New')"
                    class="main-color"
                    large
                    @click="createPushNotification"
                    v-can="'add-push-notifications'"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Working Days -->
        <v-tab-item>
          <v-card flat>
            <v-card-text v-if="configureVacations">
              <v-row>
                <v-col
                  :class="
                    currentAppLocale == 'en' || currentAppLocale == undefined
                      ? 'text-right'
                      : 'text-left'
                  "
                >
                  <!-- ================== Activity logs ================== -->
                  <v-btn
                    icon
                    to="/systemlogging/working_days"
                    v-if="activitylogPermission"
                    target="_blank"
                    class=""
                  >
                    <v-icon class="main-color" large :title="$t('System Log')"
                      >mdi-archive-clock</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <p class="colTitle mb-0">{{ $t("Working Days") }}</p>
                </v-col>
                <v-col cols="12" md="9">
                  <v-row>
                    <v-checkbox class="mx-2" :label="$i18n.t('Saturday')" v-model="nonWorkingDaysObj.workingDays" :value="1"
                      @click="changeWorkingDays"></v-checkbox>
                    <v-checkbox class="mx-2" :label="$i18n.t('Sunday')" v-model="nonWorkingDaysObj.workingDays" :value="2"
                      @click="changeWorkingDays"></v-checkbox>
                    <v-checkbox class="mx-2" :label="$i18n.t('Monday')" v-model="nonWorkingDaysObj.workingDays" :value="3"
                      @click="changeWorkingDays"></v-checkbox>
                    <v-checkbox class="mx-2" :label="$i18n.t('Tuesday')" v-model="nonWorkingDaysObj.workingDays" :value="4"
                      @click="changeWorkingDays"></v-checkbox>
                    <v-checkbox class="mx-2" :label="$i18n.t('Wednesday')" v-model="nonWorkingDaysObj.workingDays" :value="5"
                      @click="changeWorkingDays"></v-checkbox>
                    <v-checkbox class="mx-2" :label="$i18n.t('Thursday')" v-model="nonWorkingDaysObj.workingDays" :value="6"
                      @click="changeWorkingDays"></v-checkbox>
                    <v-checkbox class="mx-2" :label="$i18n.t('Friday')" v-model="nonWorkingDaysObj.workingDays" :value="7"
                      @click="changeWorkingDays"></v-checkbox>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4" md="3">
                  <p class="colTitle mb-0">{{ $t("Week Starts From") }}</p>
                </v-col>
                <v-col cols="12" sm="8" md="9" class="radio-section">
                  <v-radio-group row v-model="nonWorkingDaysObj.weekStart">
                    <v-radio
                      :label="$i18n.t('Saturday')"
                      :value="1"
                      :disabled="checkStartDay(1)"
                    ></v-radio>
                    <v-radio
                      :label="$i18n.t('Sunday')"
                      :value="2"
                      :disabled="checkStartDay(2)"
                    ></v-radio>
                    <v-radio
                      :label="$i18n.t('Monday')"
                      :value="3"
                      :disabled="checkStartDay(3)"
                    ></v-radio>
                    <v-radio
                      :label="$i18n.t('Tuesday')"
                      :value="4"
                      :disabled="checkStartDay(4)"
                    ></v-radio>
                    <v-radio
                      :label="$i18n.t('Wednesday')"
                      :value="5"
                      :disabled="checkStartDay(5)"
                    ></v-radio>
                    <v-radio
                      :label="$i18n.t('Thursday')"
                      :value="6"
                      :disabled="checkStartDay(6)"
                    ></v-radio>
                    <v-radio
                      :label="$i18n.t('Friday')"
                      :value="7"
                      :disabled="checkStartDay(7)"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="3">
                  <p class="colTitle mb-0">{{ $t("Non-Working Days") }}</p>
                </v-col>
                <v-col cols="12" sm="8" md="9">
                  <v-chip-group column active-class="primary--text">
                    <div
                      v-for="day in nonWorkingDaysObj.nonWorking"
                      :key="day.id"
                    >
                      <v-chip
                        class="ma-2"
                        color="#e1e9ff"
                        text-color="#5A86FF"
                        close
                        @click="EditNonWorkingDay(day)"
                        @click:close="openConfirmDialog(day)"
                      >
                        {{ day.date_format }}
                        <!-- <v-icon>close</v-icon> <br /> -->
                      </v-chip>
                      <!-- <p class="notice">{{ day.name }}</p> -->
                      <p class="notice">
                        <v-tooltip bottom max-width="200px" v-if="day.name">
                          <template v-slot:activator="{ on }">
                            <p dark v-on="on">
                              {{ day.name ? day.name.slice(0, 20) : "" }}
                            </p>
                          </template>
                          <span>{{ day.name }}</span>
                        </v-tooltip>
                      </p>
                    </div>
                  </v-chip-group>
                  <div id="addDay">
                    <span @click="openDialog">
                      <v-icon class="main-color" :title="$t('Add Date')" large
                        >add_circle</v-icon
                      >{{ $t("Add Date") }}
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="3">
                  <p class="colTitle mb-0">
                    {{ $t("Number Of Lessons per day") }}
                  </p>
                </v-col>
                <v-col cols="12" sm="8" md="9">
                  <v-text-field
                    v-model="lessonsCount"
                    solo
                    :rules="lessonsRule"
                    @change="setLessonsCount"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- zoomConfiguration -->
        <v-tab-item>
          <v-card-text v-if="configureVacations">
            <zoomConfiguration></zoomConfiguration>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <!-- <v-btn
              class="modal-btn-save saveBtn"
              @click="saveZoomConfigration"
              :loading="loading"
              :disabled="loading"
              >Save</v-btn
            >-->
          </v-card-actions>
        </v-tab-item>

        <!-- sms stmp tab item-->
        <v-tab-item>
          <v-row v-if="configureVacations">
            <v-col cols="12">
              <v-form
                ref="form"
                v-model="valid"
                style="padding-left: 15px !important"
              >
                <v-row>
                  <v-col>
                    <p style="padding: 15px">
                      <b>{{ $t("SMS Configration") }}</b>
                    </p>
                  </v-col>
                  <v-col
                    :class="
                      currentAppLocale == 'en' || currentAppLocale == undefined
                        ? 'text-right'
                        : 'text-left'
                    "
                  >
                    <!-- ================== Activity logs ================== -->
                    <v-btn
                      icon
                      to="/systemlogging/sms_configrations"
                      v-if="activitylogPermission"
                      target="_blank"
                      class="mt-2"
                    >
                      <v-icon class="main-color" large :title="$t('System Log')"
                        >mdi-archive-clock</v-icon
                      >
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- <v-checkbox
                  class="mx-2"
                  label="Jwal SMS"
                  v-model="smsConfigration.jwal"
                  @oncklick="smsConfigration.jwal = true"
                ></v-checkbox>-->
                <v-row>
                  <v-col cols="12" md="7" class="text-left">
                    <v-radio-group v-model="smsConfigration.provider_id">
                      <v-radio :label="$t('jawal b sms')" :value="1"></v-radio>
                      <v-radio :label="$t('Qyadat')" :value="2"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-switch
                      v-model="smsConfigration.status"
                      :label="$t('SMS Active')"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="3">
                    <p>
                      {{ $t("SMS Balance") }} : {{ balance }}
                      {{ $t("SMS") }}
                    </p>
                  </v-col>
                </v-row>

                <v-row v-if="smsConfigration.provider_id == 1">
                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("Sender Name") }}</label>
                    <v-text-field
                      v-model="smsConfigration.sms[0].configration.from"
                      :rules="[validationRules.required]"
                      @focus="onFocus()"
                      solo
                    ></v-text-field>

                    <div
                      v-if="validation_errors.sms.configration.from"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.sms.configration.from &&
                          validation_errors.sms.configration.from > 0
                        "
                      >
                        {{ validation_errors.sms.configration.from }}
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("User") }}</label>
                    <v-text-field
                      v-model="smsConfigration.sms[0].configration.user"
                      @focus="onFocus()"
                      :rules="[validationRules.required]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="validation_errors.sms.configration.user"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.sms.configration.user &&
                          validation_errors.sms.configration.user > 0
                        "
                      >
                        {{ validation_errors.sms.configration.user }}
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("Password") }}</label>
                    <v-text-field
                      v-model="smsConfigration.sms[0].configration.password"
                      @focus="onFocus()"
                      :rules="[validationRules.required]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="validation_errors.sms.configration.password"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.sms.configration.password &&
                          validation_errors.sms.configration.password > 0
                        "
                      >
                        {{ validation_errors.sms.configration.password }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("Min Balance") }}</label>
                    <v-text-field
                      v-model="smsConfigration.sms[0].configration.minBalance"
                      @focus="onFocus()"
                      :rules="[
                        validationRules.required,
                        validationRules.numeric,
                      ]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="validation_errors.sms.configration.minBalance"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.sms.configration.minBalance &&
                          validation_errors.sms.configration.minBalance > 0
                        "
                      >
                        {{ validation_errors.sms.configration.minBalance }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("Email TO send SMS Balance") }}</label>
                    <v-text-field
                      v-model="
                        smsConfigration.sms[0].configration
                          .emailToSendSmsBalance
                      "
                      @focus="onFocus()"
                      :rules="[validationRules.required]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="
                        validation_errors.sms.configration.emailToSendSmsBalance
                      "
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.sms.configration
                            .emailToSendSmsBalance &&
                          validation_errors.sms.configration
                            .emailToSendSmsBalance > 0
                        "
                      >
                        {{
                          validation_errors.sms.configration
                            .emailToSendSmsBalance
                        }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="smsConfigration.provider_id == 2">
                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("Sender Name") }}</label>
                    <v-text-field
                      v-model="smsConfigration.sms[1].configration.from"
                      :rules="[validationRules.required]"
                      @focus="onFocus()"
                      solo
                    ></v-text-field>

                    <div
                      v-if="validation_errors.sms.configration.from"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.sms.configration.from &&
                          validation_errors.sms.configration.from > 0
                        "
                      >
                        {{ validation_errors.sms.configration.from }}
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("User") }}</label>
                    <v-text-field
                      v-model="smsConfigration.sms[1].configration.user"
                      @focus="onFocus()"
                      :rules="[validationRules.required]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="validation_errors.sms.configration.user"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.sms.configration.user &&
                          validation_errors.sms.configration.user > 0
                        "
                      >
                        {{ validation_errors.sms.configration.user }}
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("Password") }}</label>
                    <v-text-field
                      v-model="smsConfigration.sms[1].configration.password"
                      @focus="onFocus()"
                      :rules="[validationRules.required]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="validation_errors.sms.configration.password"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.sms.configration.password &&
                          validation_errors.sms.configration.password > 0
                        "
                      >
                        {{ validation_errors.sms.configration.password }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("Min Balance") }}</label>
                    <v-text-field
                      v-model="smsConfigration.sms[1].configration.minBalance"
                      @focus="onFocus()"
                      :rules="[
                        validationRules.required,
                        validationRules.numeric,
                      ]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="validation_errors.sms.configration.minBalance"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.sms.configration.minBalance &&
                          validation_errors.sms.configration.minBalance > 0
                        "
                      >
                        {{ validation_errors.sms.configration.minBalance }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("Email TO send SMS Balance") }}</label>
                    <v-text-field
                      v-model="
                        smsConfigration.sms[1].configration
                          .emailToSendSmsBalance
                      "
                      @focus="onFocus()"
                      :rules="[validationRules.required]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="
                        validation_errors.sms.configration.emailToSendSmsBalance
                      "
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.sms.configration
                            .emailToSendSmsBalance &&
                          validation_errors.sms.configration
                            .emailToSendSmsBalance > 0
                        "
                      >
                        {{
                          validation_errors.sms.configration
                            .emailToSendSmsBalance
                        }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <hr style="border-color: #444" />

          <v-row v-if="configureVacations">
            <v-col cols="12">
              <v-form
                ref="form"
                v-model="valid"
                style="padding-left: 15px !important"
              >
                <v-row>
                  <v-col cols="12" md="7" class="text-left">
                    <p style="padding: 15px; text-align: left">
                      <b>{{ $t("SMTP Configration") }}</b>
                    </p>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-switch
                      v-model="smsConfigration.email.status"
                      :label="$t('Email Active')"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("SMTP Server") }}</label>
                    <v-text-field
                      v-model="smsConfigration.email.configration.mail_host"
                      :rules="[validationRules.required]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="validation_errors.sms.configration.user"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.email.configration.mail_host &&
                          validation_errors.sms.configration.mail_host > 0
                        "
                      >
                        {{ validation_errors.sms.configration.mail_host }}
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("Mail Port") }}</label>
                    <v-text-field
                      v-model="smsConfigration.email.configration.mail_port"
                      :rules="[
                        validationRules.required,
                        validationRules.numeric,
                      ]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="validation_errors.sms.configration.mail_port"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.email.configration.mail_port &&
                          validation_errors.email.configration.mail_port > 0
                        "
                      >
                        {{ validation_errors.email.configration.mail_port }}
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <label>{{ $t("Security") }}</label>

                    <v-select
                      solo
                      v-model="smsConfigration.email.configration.security"
                      :items="securtyselectitems"
                      :rules="[validationRules.required]"
                      :label="$t('Securty')"
                    ></v-select>
                    <div
                      v-if="validation_errors.sms.configration.security"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.email.configration.security &&
                          validation_errors.email.configration.security > 0
                        "
                      >
                        {{ validation_errors.email.configration.security }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <label>{{ $t("Mail Usename") }}</label>
                    <v-text-field
                      v-model="smsConfigration.email.configration.mail_username"
                      :rules="[validationRules.required, validationRules.email]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="validation_errors.sms.configration.mail_username"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.email.configration.mail_username &&
                          validation_errors.email.configration.mail_username > 0
                        "
                      >
                        {{ validation_errors.email.configration.mail_username }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <label>{{ $t("Mail Password") }}</label>
                    <v-text-field
                      v-model="smsConfigration.email.configration.mail_password"
                      :rules="[validationRules.required]"
                      solo
                    ></v-text-field>
                    <div
                      v-if="validation_errors.sms.configration.mail_password"
                      class="validation"
                    >
                      <p
                        class="error--text"
                        v-if="
                          validation_errors.email.configration.mail_password &&
                          validation_errors.email.configration.mail_password > 0
                        "
                      >
                        {{ validation_errors.email.configration.mail_password }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-spacer></v-spacer>
                <div class="text-right mb-4">
                  <v-btn
                    class="modal-btn-save"
                    @click="saveDatasmsConfigration"
                    :loading="loading"
                    :disabled="loading"
                  >
                    {{ $t("Save") }}</v-btn
                  >
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- / sms stmp tab item -->

        <!-- Warehouse-->
        <v-tab-item v-if="viewWarwhouse">
          <v-card-text>
            <multiWareHousesConfig />
          </v-card-text>
        </v-tab-item>
        <!-- /Warehouse -->

        <!-- Announcement-->
        <v-tab-item>
          <AnnouncementsComponent
            v-if="configureVacations"
          ></AnnouncementsComponent>
        </v-tab-item>
        <!-- student Documents -->
        <v-tab-item>
          <studentDocuments></studentDocuments>
        </v-tab-item>

        <!-- Attendace Note -->
        <v-tab-item>
          <attendanceNote></attendanceNote>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" color="success" :right="true" :top="true">
      {{ text }}
      <v-btn color="pink" text @click="snackbar = false">{{
        $t("Close")
      }}</v-btn>
    </v-snackbar>

    <!-- confirm of delete non-working day -->
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text
          >{{ $t("Are you sure you want to delete") }}
          {{ selected.name }}?</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="closeConfirmDialog">{{
            $t("No")
          }}</v-btn>
          <v-btn @click="deleteDay" class="modal-btn-save">{{
            $t("Yes")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add edit none working day item -->
    <v-dialog v-model="addEditDialog" persistent max-width="600" class="py-4">
      <v-card>
        <v-card-text>
          <v-row class="padding-top">
            <v-col cols="12" md="6">
              <v-card-title class="headline">{{
                $t("Non-Working Days")
              }}</v-card-title>
            </v-col>
            <v-col cols="12" md="6">
              <v-radio-group row v-model="defaultObject.type" :disabled="edit">
                <v-radio :label="$t('Specific Day')" value="day"></v-radio>
                <v-radio
                  :label="$t('Specific Period')"
                  value="period"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Name") }}</label>
                  <v-text-field
                    v-model="defaultObject.name"
                    :rules="nameRules"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row :hidden="day">
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Date") }}</label>
                  <v-text-field
                    v-model="defaultObject.date"
                    prepend-icon="calendar_today"
                    autocomplete="off"
                    :rules="[dateRequiredRuleDay(defaultObject.date)]"
                    id="dateValue"
                    solo
                    @keydown.prevent
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12">
                  <label>{{ $t("Image") }}</label>
                </v-col>
                <v-col cols="2" sm="1">
                  <v-checkbox
                    v-model="defaultObject.show_image"
                    style="padding-top: 10px"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="11" md="11">
                  <template>
                    <v-file-input
                      ref="myFile"
                      show-size
                      v-model="defaultObject.image"
                      accept="image/*"
                      solo
                      prepend-icon="mdi-camera"
                    ></v-file-input>
                  </template>
                  <p>{{ $t("Optimal Dimensions") }} 3200*410</p>
                </v-col>
              </v-row> -->
              <v-row :hidden="period">
                <v-col cols="12" sm="6">
                  <label>{{ $t("From") }}</label>
                  <v-text-field
                    v-model="defaultObject.from"
                    append-icon="calendar_today"
                    autocomplete="off"
                    id="fromDate"
                    :rules="[
                      dateRequiredRulePeriod(defaultObject.from),
                      startDateRule(defaultObject.from, defaultObject.to),
                    ]"
                    solo
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <label>{{ $t("To") }}</label>
                  <v-text-field
                    v-model="defaultObject.to"
                    append-icon="calendar_today"
                    autocomplete="off"
                    id="to"
                    :rules="[
                      dateRequiredRulePeriod(defaultObject.to),
                      startDateRule(defaultObject.from, defaultObject.to),
                    ]"
                    solo
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-row class="my-4">
                  <v-col cols="6" sm="6">
                    <label>{{ $t("Color Theme") }}</label>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <label>{{ $t("Font Color") }}</label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2" sm="1">
                    <v-checkbox
                      v-model="disableColorPicker"
                      @change="returnColorDefault"
                      style="padding-top: 10px"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="10" md="5">
                    <v-text-field
                      v-model="defaultObject.colorTheme"
                      hide-details
                      class="ma-0 pa-0"
                      solo
                      :disabled="!disableColorPicker"
                    >
                      <template v-slot:append>
                        <v-menu
                          v-model="menu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="true"
                          :disabled="!disableColorPicker"
                        >
                          <template v-slot:activator="{ on }">
                            <div :style="swatchStyle" v-on="on" />
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker
                                hide-inputs
                                v-model="defaultObject.colorTheme"
                                flat
                              />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2" sm="1">
                    <v-checkbox
                      v-model="disableColorPickerFont"
                      @change="returnColorDefaultFont"
                      style="padding-top: 10px"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="10" md="5">
                    <v-text-field
                      v-model="defaultObject.font_color"
                      hide-details
                      class="ma-0 pa-0"
                      solo
                      :disabled="!disableColorPickerFont"
                    >
                      <template v-slot:append>
                        <v-menu
                          v-model="menufont"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="true"
                          :disabled="!disableColorPickerFont"
                        >
                          <template v-slot:activator="{ on }">
                            <div :style="swatchStyleFont" v-on="on" />
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker
                                hide-inputs
                                v-model="defaultObject.font_color"
                                flat
                              />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-col cols="12" sm="12" md="12" class="my-4">
                  <label for="Notes">{{ $t("Notes") }}</label>
                  <v-textarea
                    v-model="defaultObject.notes"
                    solo
                    :rules="[NotesRule(defaultObject.notes)]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="closeAddEditDialog">{{
            $t("Close")
          }}</v-btn>
          <v-btn class="modal-btn-save" @click="saveData">{{
            $t("Save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Users To Wherehose  -->

    <v-dialog v-model="addUsers" persistent max-width="500px">
      <v-card>
        <v-card-text>
          <v-row class="padding-top">
            <v-col cols="12" md="12">
              <v-card-title class="headline">{{
                $t("Warehouse Assignee")
              }}</v-card-title>
            </v-col>
          </v-row>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Assignees") }}</label>
                  <v-autocomplete
                    v-model="warehouse.assignees"
                    :items="allUsers"
                    item-text="name"
                    item-value="id"
                    chips
                    label
                    multiple
                    @change="pushInAssigneeNames"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="closeDialogUsers">{{
            $t("Close")
          }}</v-btn>
          <!-- <v-btn @click="savUsers" class="modal-btn-save">Yes</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete push notifcations -->
    <v-dialog
      v-model="dialogReset"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to Delet this Notification ?")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="dialogReset = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteItem(editedItem)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack"
      :color="snackColor"
      class="white--text"
      :top="true"
      :right="true"
    >
      {{ snackText }}
      <v-btn color="pink" text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { validationMixin } from "../../mixins/validationMixin";

import zoomConfiguration from "../../components/zoomComponents/zoomConfiguration";
import AnnouncementsComponent from "../../components/AnnouncementsComponent";
import multiWareHousesConfig from "@/components/warehouses/multiWareHousesConfig";
import studentDocuments from "../../components/configuration/studentDocuments";
import attendanceNote from "../../components/configuration/attendanceNote";
import ACL from "../../acl";

export default {
  name: "WorkingDays",
  components: {
    zoomConfiguration,
    AnnouncementsComponent,
    multiWareHousesConfig,
    studentDocuments,
    attendanceNote
  },
  mixins: [validationMixin],
  data() {
    return {
      // log activities permission
      activitylogPermission: ACL.checkPermission("activitylog"),

      dialogReset: false,
      balance: "",
      timeout: 600,
      loading: false,
      zoomValid: false,
      warehouseEditMode: false,
      zoom: {
        api_key: "",
        api_secret_key: "",
        jwt_token: "",
        account_username: "",
      },
      securtyselectitems: ["SSL", "Auto", "None", "Tls", "Tls when available"],
      smsConfigration: {
        sms: {
          id: "",
          type: "sms",
          status: "",
          sms_provider_id: "",
          configration: [],
        },
        email: {
          id: "",
          type: "email",
          status: "",
          configration: [],
          sms_provider_id: "",
        },
        provider_id: "",
        status: "",
      },
      warehouse: {
        id: "",
        code: "",
        name: "",
        short_name: "",
        items_categories: [],
        assignees: [],
        assignees_names: [],
      },
      headersNotifcations: [
        {
          text: this.$i18n.t("Subject"),
          value: "subject",
          sortable: false,
        },
        {
          text: this.$i18n.t("Message"),
          value: "message",
          sortable: false,
        },
        {
          text: this.$i18n.t("Audience"),
          value: "audience",
          sortable: false,
        },

        {
          text: this.$i18n.t("Nationality"),
          value: "nationality",
          sortable: false,
        },
        {
          text: this.$i18n.t("Time"),
          value: "time",
          sortable: false,
        },
        {
          text: this.$i18n.t("Linked To"),
          value: "linked_to",
          sortable: false,
        },
        {
          text: this.$i18n.t("Platform"),
          value: "platform",
          sortable: false,
        },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      filterMenu: false,
      filter: {
        nationalites: [],
        audiences: [],
        plateform: [],
        date: "",
      },
      search: "",
      notificationsItems: [],
      nationalites: [],
      audiences: [
        { id: "1", name: this.$i18n.t("Super-admin") },
        { id: "6", name: this.$i18n.t("Accountant") },
        { id: "2", name: this.$i18n.t("Coordinator") },
        { id: "3", name: this.$i18n.t("Teacher") },
        { id: "5", name: this.$i18n.t("Parent") },
        { id: "4", name: this.$i18n.t("Student") },
      ],
      plateform: [this.$i18n.t("web"), this.$i18n.t("mobile")],
      circle: true,
      page: 1,

      length: "",
      totalVisible: "",
      allUsers: [],
      addUsers: false,
      validation_errors: {
        api_key: "",
        api_secret_key: "",
        jwt_token: "",
        account_username: "",

        sms: {
          configration: [],
        },
        email: {
          configration: [],
        },
        warehouse: {
          id: "",
          code: "",
          name: "",
          short_name: "",
        },
      },
      tab: 0,
      snack: false,
      snackColor: "",
      snackText: "",
      lessonsRule: [
        (v) =>
          (v && /^\d+$/.test(v)) ||
          this.$i18n.t("This field only accept numbers"),
      ],
      disableColorPickerFont: false,
      disableColorPicker: false,
      color: "#1976D2FF",
      mask: "!#XXXXXXXX",
      menu: false,
      menufont: false,
      day: false,
      period: true,
      confirmDialog: false,
      addEditDialog: false,
      selected: {},
      ThemeColor: "",
      showColorPicker: false,
      snackbar: false,
      text: "",

      nonWorkingDaysObj: {
        workingDays: [],
        weekStart: "",
        nonWorking: [],
      },

      defaultObject: {
        type: "day",
        date: "",
        from: "",
        to: "",
        name: "",
        notes: "",
        image: "",
        colorTheme: "#7297FF",
        font_color: "#000000",
      },
      edit: false,
      /* validation */
      valid: true,
      editedItem: {},
      nameRules: [
        (v) => !!v || this.$i18n.t("This field is required"),
        (v) =>
          (v && v.length <= 50) || "This field must be less than 50 characters",
      ],

      lessonsCount: 0,
      viewWarwhouse: ACL.checkPermission("view-warehouse-setup"),
      viewPushNotifcation: ACL.checkPermission("view-push-notification"),
      editWarwhouse: ACL.checkPermission("edit-warehouse-setup"),
      configureVacations: ACL.checkPermission("configure-vacations"),
      attendance: ACL.checkPermission("attendance-comment-setup"),
    };
  },
  computed: {
    swatchStyle() {
      const { menu } = this;
      return {
        backgroundColor: this.defaultObject.colorTheme,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    swatchStyleFont() {
      const { menu } = this;
      return {
        backgroundColor: this.defaultObject.font_color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.tab == 0) {
          if (this.$route.query.page !== undefined) {
            this.getAllData(this.$route.query.page);
          } else {
            this.getAllData(1);
          }
        }

        if (this.$route.query.tab !== undefined) {
          this.tab = Number(this.$route.query.tab);
        }
      },
    },

    page: function () {
      this.$router.push(
        {
          path: "/workingdays?tab=" + this.tab + "&page=" + this.page,
        },
        () => {}
      );
    },

    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },

    // "nonWorkingDaysObj.workingDays": {
    //   handler() {
    //     this.changeWorkingDays();

    //     let startWeek = this.nonWorkingDaysObj.weekStart;

    //     if (!this.nonWorkingDaysObj.workingDays.includes(startWeek)) {
    //       this.nonWorkingDaysObj.weekStart = "";
    //     }
    //   },
    // },
    "nonWorkingDaysObj.weekStart": {
      handler() {
        if (this.nonWorkingDaysObj.weekStart) {
          this.changeWeekStart();
        }
      },
    },
    "defaultObject.type": {
      handler() {
        if (this.defaultObject.type == "day") {
          this.defaultObject.from = "";
          this.defaultObject.to = "";
          this.day = false;
          this.period = true;
        } else if (this.defaultObject.type == "period") {
          this.defaultObject.date = "";
          this.period = false;
          this.day = true;
        }
        this.activateDatePicker();
      },
    },
    // lessonsCount: {
    //   handler() {
    //     if (/^\d+$/.test(this.lessonsCount)) {
    //       this.setLessonsCount();
    //     }
    //   }
    // }
  },
  methods: {
    customFilter(filterName) {
      console.log(filterName);
      this.filter.filterName = "true";
    },
    editNotifcation(item) {
      this.$router.push(
        {
          path: "/editPushNotification/" + item.id,
        },
        () => {}
      );
    },
    openDia(item) {
      this.dialogReset = true;
      this.editedItem = item;
    },
    deleteItem(item) {
      console.log(item);

      axios
        .get(
          this.getApiUrl + "/push_notifications/destroy/" + this.editedItem.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.dialogReset = false;
          console.log(response.data);
          this.snack = true;
          this.snackColor = "green";
          this.snackText = response.data.status.message;
          this.getAllData();
        });
    },
    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl +
            "/push_notifications?search=" +
            this.search +
            "&page=" +
            page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.notificationsItems = [];
          this.notificationsItems = response.data.data.data;
          this.loading = false;
        });
    },

    // getAnnouncementsData(page = null) {
    //   if (page == null) {
    //     page = this.announcementsPage;
    //   }
    //   axios
    //     .get(
    //       this.getApiUrl +
    //         "/announcements?search=" +
    //         this.searchAnnouncements +
    //         "&page=" +
    //         page,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.token,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       this.setPaginationParameters(response);
    //       this.AnnouncementsItems = [];
    //       this.AnnouncementsItems = response.data.data.data;
    //       this.loading = false;
    //     });
    // },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    createPushNotification() {
      this.$router.push(
        {
          path: "/createPushNotification",
        },
        () => {}
      );
    },
    getCountries() {
      axios.get(this.getApiUrl + "/getCountries").then((response) => {
        this.nationalites = response.data.data;
      });
    },
    getRoles() {
      axios.get(this.getApiUrl + "/getCountries").then((response) => {
        this.audiences = response.data.data;
      });
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },
    pushInAssigneeNames() {
      this.warehouse.assignees_names = [];
      this.warehouse.assignees.forEach((assignee) => {
        this.warehouse.assignees_names.push({
          id: assignee,
          name: this.allUsers.find((obj) => obj.id == assignee).name,
        });
      });
    },
    routeTab(tab) {
      this.$router.push(
        {
          path: "/workingdays?tab=" + tab,
        },
        () => {}
      );
      this.tab = tab;
      console.log(tab);
    },
    saveZoomConfigration() {
      if (this.zoomValid == false) {
        this.validation_errors = [];
        this.validate();
      } else {
        axios
          .post(
            this.getApiUrl + "/online_meeting/store_configration",
            { configration: this.zoom },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.snack = true;
              this.snackColor = "green";
              this.snackText = response.data.status.message;
              this.validation_errors = [];
            } else {
              this.validation_errors =
                response.data.status.validation_errors.configration;
            }
          });
      }
    },
    getZoomConfigration() {
      axios
        .get(
          this.getApiUrl + "/online_meeting/get_configration",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            if (response.data.data != null) this.zoom = response.data.data;
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = "Error getting configration data";
          }
        });
    },
    getSmtpSmsConfigrations() {
      axios
        .get(
          this.getApiUrl + "/school/getSmtpSmsConfigrations",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            if (response.data.data != null)
              this.smsConfigration = response.data.data;
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = "Error getting configration data";
          }
        });
    },
    saveDatasmsConfigration() {
      if (this.valid) {
        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/school/storeSmtpSmsConfigrations",
            this.smsConfigration,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == false) {
              this.getSmtpSmsConfigrations();
              this.snack = true;
              this.snackColor = "green";
              this.snackText = "Success Saved Success";
            }
          });

        this.closeAddEditDialog();
      } else {
        this.$refs.form.validate();
      }
    },
    returnColorDefault() {
      if (this.disableColorPicker == false) {
        this.defaultObject.colorTheme = "#7297FF";
      }
    },
    returnColorDefaultFont() {
      if (this.disableColorPickerFont == false) {
        this.defaultObject.font_color = "#000000";
      }
    },
    openConfirmDialog(day) {
      this.selected = day;

      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.selected = {};
      this.confirmDialog = false;
    },
    deleteDay() {
      const dayId = this.selected.id;
      axios
        .get(this.getApiUrl + "/school/non_working_days/destroy/" + dayId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            const index = this.nonWorkingDaysObj.nonWorking.indexOf(
              this.selected
            );
            this.nonWorkingDaysObj.nonWorking.splice(index, 1);
            this.closeConfirmDialog();
            this.snackbar = true;
            this.text = "Deleted Successfully";
            this.$store.commit("setVacationDays");
          }
        });
    },
    openDialog() {
      this.addEditDialog = true;
      this.defaultObject = {
        type: "day",
        date: "",
        from: "",
        to: "",
        name: "",
        notes: "",
        colorTheme: "#7297FF",
        font_color: "#000000",
      };
      this.disableColorPickerFont = false;
      this.disableColorPicker = false;
      this.activateDatePickerDay();
    },
    openDialogUsers() {
      this.addUsers = true;
    },
    closeDialogUsers() {
      this.addUsers = false;
    },
    deleteAssignee(index) {
      this.warehouse.assignees_names.splice(index, 1);
      this.warehouse.assignees.splice(index, 1);
    },

    getWarehouses() {
      axios
        .get(this.getApiUrl + "/warehouses/getWarehouses", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.warehouse = response.data.data;
        });
    },
    EditNonWorkingDay(day) {
      this.edit = true;
      this.addEditDialog = true;
      this.defaultObject = day;

      if (this.defaultObject.colorTheme) {
        this.showColorPicker = true;
        this.disableColorPicker = true;
      }
      if (this.defaultObject.font_color) {
        // this.showColorPickerFont = true;
        this.disableColorPickerFont = true;
      }
      this.activateDatePickerDay();
    },

    closeAddEditDialog() {
      this.addEditDialog = false;
      this.defaultObject = {
        type: "day",
        date: "",
        from: "",
        to: "",
        name: "",
        notes: "",
        colorTheme: "",
        font_color: "",
      };

      this.getNonWorkingDays();
      this.edit = false;
      this.$refs.form.resetValidation();
      this.showColorPicker = false;
    },

    saveData() {
      if (this.valid) {
        let formData = new FormData();

        // files

        formData.append("image", this.defaultObject.image);

        // additional data
        formData.append("colorTheme", this.defaultObject.colorTheme);
        formData.append("date", this.defaultObject.date);
        formData.append("font_color", this.defaultObject.font_color);
        formData.append("from", this.defaultObject.from);
        formData.append("name", this.defaultObject.name);
        formData.append("notes", this.defaultObject.notes);
        formData.append("to", this.defaultObject.to);
        formData.append("type", this.defaultObject.type);
        formData.append("show_image", this.defaultObject.show_image);

        if (this.edit == true) {
          let id = this.defaultObject.id;
          axios
            .post(
              this.getApiUrl + "/school/updateNonWorkingDay/" + id,
              formData,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == false) {
                this.nonWorkingDaysObj = response.data.data;

                this.$store.commit("setVacationDays");
                location.reload();
              }
            });
        } else {
          axios
            .post(this.getApiUrl + "/school/storeNonWorkingDay", formData, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              if (response.data.status.error == false) {
                this.nonWorkingDaysObj = response.data.data;
                this.$store.commit("setVacationDays");
                location.reload();
              }
            });
        }
        this.closeAddEditDialog();
      } else {
        this.$refs.form.validate();
      }
    },

    getNonWorkingDays() {
      axios
        .get(this.getApiUrl + "/school/non_working_days", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.nonWorkingDaysObj = response.data.data;
        });
    },
    changeWorkingDays() {
      const workingDaysids = this.nonWorkingDaysObj.workingDays;

      let startWeek = this.nonWorkingDaysObj.weekStart;

      if (!this.nonWorkingDaysObj.workingDays.includes(startWeek)) {
        this.nonWorkingDaysObj.weekStart = "";
      }
      axios
        .post(
          this.getApiUrl + "/school/changeWorkingDays",
          {
            ids: workingDaysids,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == true) {
            alert("something went wrong");
          }
        });
    },

    changeWeekStart() {
      const weekStart = this.nonWorkingDaysObj.weekStart;
      axios
        .get(this.getApiUrl + "/school/changeWeekStart/" + weekStart, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == true) {
            alert("something went wrong");
          }
        });
    },

    activateDatePickerDay() {
      let _this = this;

      $(document).ready(function () {
        $("#dateValue").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateValue =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.defaultObject.date = dateValue;
            } else {
              _this.defaultObject.date = "";
            }
          },
        });

        $("#fromDate").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateFrom =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.defaultObject.from = dateFrom;
            } else {
              _this.defaultObject.from = "";
            }
          },
        });
        $("#to").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateTo =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.defaultObject.to = dateTo;
            } else {
              _this.defaultObject.to = "";
            }
          },
        });
      });
    },

    checkStartDay(id) {
      if (!this.nonWorkingDaysObj.workingDays.includes(id)) {
        return true;
      } else {
        return false;
      }
    },

    /* validation */
    NotesRule(notes) {
      if (notes != "" && notes != undefined && notes != null) {
        if (notes.length > 100) {
          return "This field must be less than 100 characters";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    dateRequiredRuleDay(date) {
      if (this.defaultObject.type == "day") {
        if (date.length < 1) {
          return this.$i18n.t("This field is required");
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    dateRequiredRulePeriod(date) {
      if (this.defaultObject.type == "period") {
        if (date.length < 1) {
          return this.$i18n.t("This field is required");
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    startDateRule(start, end) {
      var startDate = moment(start, "DD/MM/YYYY");
      var endDate = moment(end, "DD/MM/YYYY");
      return startDate >= endDate
        ? "Start date should be less than end date"
        : true;
    },
    getLessonsCount() {
      axios
        .get(
          this.getApiUrl + "/schedules/getLessonsCount/" + localStorage.school,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((res) => {
          this.lessonsCount = res.data.data.lessons_count
            ? res.data.data.lessons_count
            : 0;
        });
    },
    setLessonsCount() {
      // console.log(this.lessonsCount);
      if (/^\d+$/.test(this.lessonsCount)) {
        axios
          .post(
            this.getApiUrl +
              "/schedules/setLessonsCount/" +
              localStorage.school,
            { count: this.lessonsCount },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((res) => {
            if (res.data.status.error) {
              this.snack = true;
              this.snackColor = "error";
              this.snackText = res.data.status.message;
            } else {
              this.snack = true;
              this.snackColor = "success";
              this.snackText = res.data.status.message;
            }
            this.lessonsCount = res.data.data.lessons_count
              ? res.data.data.lessons_count
              : 0;
          });
      }
    },
    getSmsBalance() {
      axios
        .get(this.getApiUrl + "/getSmsBalance", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((res) => {
          this.balance = res.data.data;
        });
    },
  },
  mounted() {
    // this.getAnnouncementsData(1);
    this.page = 1;
    this.getAllData(this.page);
    this.tab = Number(this.$route.query.tab);
    this.getNonWorkingDays();
    this.getCountries();
    // this.swatchStyleDefault();
    this.getLessonsCount();
    // this.getZoomConfigration();
    this.getSmtpSmsConfigrations();
    this.tab = Number(this.$route.query.tab);
    this.getWarehouses();
    this.getSmsBalance();
    $(".filter-p").on("click", function () {
      $(this).addClass("main-color").siblings().removeClass("main-color");
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
p.colTitle {
  font-size: 1.3rem;
}

p.notice {
  font-size: 0.8rem;
  text-align: center;
  position: relative;
}
p.notice:after {
  content: "";
  width: 70%;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  bottom: 0;
  left: 15%;
}

.radio-section {
  padding: 5px 12px;
}

.v-color-picker {
  margin-bottom: 1rem;
}
.colorCode {
  border: 1px solid $main-color;
  padding: 5px;
  border-radius: 5px;
  background-color: $main-background;
  min-width: 70px;
  display: inline-block;
  min-height: 25px;
}

.workingDaysContainer {
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

#addDay {
  span {
    cursor: pointer;
  }
}

.padding-top {
  padding: 0 0;
  padding-top: 1rem;
  margin-left: -33px;
}

.v-chip-group .v-chip {
  margin: 4px 8px 4px 0 !important;
}

.v-application .mx-2:first-child {
  margin-left: 12px !important;
}

.filter-p {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  display: inline;
  cursor: pointer;
}
.text-left {
  text-align: left !important;
}
</style>
