<template>
  <div class="my-4 pa-4">
    <v-row>
      <v-col
        :class="currentAppLocale == 'en' ? 'text-right' : 'text-align-left'"
      >
        <v-icon
          class="main-color"
          large
          @click="openAddEditDialog({}, true)"
          :title="$t('Add New')"
          >add_circle</v-icon
        >
      </v-col>
    </v-row>
    <v-data-table
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      item-key="id"
      :items-per-page="15"
      :show-select="false"
      class="elevation-1 level1 templates"
      text="left"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="openAddEditDialog(item)" :title="$t('Edit')">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="openConfirmDeleteDialog(item.id)"
          :title="$t('Delete')"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-pagination
      v-if="length > 0"
      v-model="page"
      :length="length"
      :circle="circle"
      :page="page"
      :total-visible="totalVisible"
      next-icon="mdi-menu-right"
      prev-icon="mdi-menu-left"
    ></v-pagination>

    <addEditStudentDocs
      v-if="openDialog"
      :studentDoc="selectedItem"
      :AddEditdialog="openDialog"
      :type="type"
      @closeDialog="closeDialog"
    ></addEditStudentDocs>

    <generalConfirmDeleteDialog
      :msg="deleteConfirmationMsg"
      :item_id="selected_id"
      :confirmDeletedialog="confirmDeletedialog"
      @closeConfirmDeleteDialog="confirmDeletedialog = false"
      @deleteItem="deleteDoc"
    />

    <snackComponent
      :snackbar="snackbar"
      :snackColor="snackColor"
      :snackMsg="snackMsg"
      @closeSnack="snackbar = false"
    ></snackComponent>
  </div>
</template>

<script>
import addEditStudentDocs from "@/components/configuration/addEditStudentDocs";
import generalConfirmDeleteDialog from "@/components/generalConfirmDeleteDialog";
import snackComponent from "@/components/snackComponent";

export default {
  components: {
    addEditStudentDocs,
    generalConfirmDeleteDialog,
    snackComponent,
  },
  data() {
    return {
      snackbar: false,
      snackColor: "green",
      snackMsg: "",
      type: "add",
      confirmDeletedialog: false,
      deleteConfirmationMsg: this.$i18n.t(
        "Are you sure you want to delete this attachement ?"
      ),

      openDialog: false,
      selectedItem: {},
      selected_id: "",
      loading: false,
      circle: true,
      page: 1,

      length: 1,
      totalVisible: "",
      headers: [
        {
          text: this.$i18n.t("Name"),
          value: "en.name",
        },
        {
          text: this.$i18n.t("Arabic Name"),
          value: "ar.name",
        },
        {
          text: this.$i18n.t("Required"),
          value: "required",
        },
        {
          text: this.$i18n.t("Active"),
          value: "active",
        },
        {
          text: this.$i18n.t("Actions"),
          value: "actions",
        },
      ],
      items: [],
    };
  },
  watch: {
    page() {
      this.getStudentDocs();
    },
  },
  methods: {
    closeDialog(afterSave) {
      this.openDialog = false;
      if (afterSave) {
        this.getStudentDocs();
      }
    },
    openAddEditDialog(item, addNew = false) {
      this.openDialog = true;
      if (addNew == false) {
        this.type = "edit";
        this.selectedItem = {
          id: item.id,
          active: item.active,
          required: item.required,
          en: {
            name: item.en.name,
          },
          ar: {
            name: item.ar.name,
          },
        };
      } else {
        this.type = "add";
        this.selectedItem = {
          id: "",
          active: false,
          required: false,
          en: {
            name: "",
          },
          ar: {
            name: "",
          },
        };
      }
    },
    getStudentDocs() {
      this.loading = true;
      this.$http
        .get(this.getApiUrl + "/students/documents?page=" + this.page, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.items = response.data.data.data;
            this.length = response.data.data.last_page;
            if (this.length > 5) this.totalVisible = 5;
          }
        });
    },
    openConfirmDeleteDialog(id) {
      this.confirmDeletedialog = true;
      this.selected_id = id;
    },
    deleteDoc() {
      this.$http
        .get(
          this.getApiUrl + "/students/destroyDocuments/" + this.selected_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.confirmDeletedialog = false;
            this.getStudentDocs();
            this.snackbar = true;
            this.snackMsg = this.$i18n.t("Attachment Deleted Successfully");
            setTimeout(() => {
              this.snackbar = false;
            }, 2000);
          }
        });
    },
  },
  mounted() {
    this.getStudentDocs();
  },
};
</script>

<style></style>
