<template>
  <div>
    <!-- Delete  -->
    <v-dialog
      v-model="confirmDeletedialog"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text :class="currentAppLocale == 'ar' ? 'text-right' : ''">{{
          msg
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="cancel"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteItem(item_id)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["msg", "item_id", "confirmDeletedialog"],
  methods: {
    cancel(afterSave = false) {
      this.$emit("closeConfirmDeleteDialog", afterSave);
    },
    deleteItem(id = "") {
      this.$emit("deleteItem", id);
    },
  },
};
</script>

<style></style>
