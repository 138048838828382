var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.type == 'add')?_c('v-card-title',{staticClass:"text-h5 main-color"},[_vm._v(" "+_vm._s(_vm.$t("Add Warehouse"))+" ")]):_c('v-card-title',{staticClass:"text-h5 main-color"},[_vm._v(" "+_vm._s(_vm.$t("Edit Warehouse"))+" ")]),_c('v-card-text',{staticClass:"my-4"},[_c('v-form',{ref:"form",class:_vm.currentAppLocale == 'en' ? '' : 'text-right',model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"items",staticStyle:{"padding":"0 20px"}},[_c('div',{staticClass:"item-form form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Code")))]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validationRules.required,
                      _vm.validationRules.maxLength50,
                    ],"solo":""},model:{value:(_vm.warehouse.code),callback:function ($$v) {_vm.$set(_vm.warehouse, "code", $$v)},expression:"warehouse.code"}}),(_vm.validation_errors.warehouse.code)?_c('div',{staticClass:"validation"},_vm._l((_vm.validation_errors.warehouse.code),function(codeError){return _c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(codeError)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"item-form form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Name")))]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validationRules.required,
                      _vm.validationRules.maxLength100,
                    ],"solo":""},model:{value:(_vm.warehouse.name),callback:function ($$v) {_vm.$set(_vm.warehouse, "name", $$v)},expression:"warehouse.name"}}),(_vm.validation_errors.warehouse.name)?_c('div',{staticClass:"validation"},[(
                        _vm.validation_errors.warehouse.name &&
                        _vm.validation_errors.warehouse.name > 0
                      )?_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.validation_errors.warehouse.name)+" ")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"item-form form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Short Name")))]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validationRules.required,
                      _vm.validationRules.maxLength10,
                    ],"solo":""},model:{value:(_vm.warehouse.short_name),callback:function ($$v) {_vm.$set(_vm.warehouse, "short_name", $$v)},expression:"warehouse.short_name"}}),(_vm.validation_errors.warehouse.short_name)?_c('div',{staticClass:"validation"},[(
                        _vm.validation_errors.warehouse.short_name &&
                        _vm.validation_errors.warehouse.short_name > 0
                      )?_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.validation_errors.warehouse.short_name)+" ")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"item-form form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Item Categories")))]),_c('v-autocomplete',{attrs:{"items":_vm.allCategories,"item-text":"name","item-value":"id","chips":"","label":"","multiple":"","solo":""},model:{value:(_vm.warehouse.items_categories),callback:function ($$v) {_vm.$set(_vm.warehouse, "items_categories", $$v)},expression:"warehouse.items_categories"}})],1)])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"items",staticStyle:{"padding":"0 20px"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Assignees")))]),_c('v-autocomplete',{attrs:{"items":_vm.allUsers,"item-text":"name","item-value":"id","chips":"","label":"","multiple":"","solo":""},model:{value:(_vm.warehouse.assignees),callback:function ($$v) {_vm.$set(_vm.warehouse, "assignees", $$v)},expression:"warehouse.assignees"}})],1)])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"modal-btn-cancel",on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c('v-btn',{staticClass:"modal-btn-save",on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }