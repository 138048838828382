<template>
  <div>
    <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline" v-if="type == 'add'">{{
            $t("Add Students Document")
          }}</span>
          <span class="headline" v-if="type == 'edit'">{{
            $t("Edit Students Document")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" v-if="item">
              <v-row>
                <v-col
                  v-if="item.en"
                  :class="currentAppLocale == 'en' ? '' : 'text-right'"
                >
                  <label for="english-name">{{ $t("English Name") }}</label>
                  <v-text-field
                    name="english-name"
                    v-model="item.en.name"
                    :rules="[validationRules.required]"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="item.ar"
                  :class="currentAppLocale == 'en' ? '' : 'text-right'"
                >
                  <label for="arabic-name">{{ $t("Arab Name") }}</label>
                  <v-text-field
                    name="arabic-name"
                    v-model="item.ar.name"
                    :rules="[
                      validationRules.required,
                      CheckArabicCharactersOnly(item.ar.name),
                    ]"
                    solo
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    :label="$t('Active')"
                    v-model="item.active"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    :label="$t('Required')"
                    v-model="item.required"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="text-right d-block">
          <hr class="hr" />

          <v-btn class="modal-btn-cancel" @click="close">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            @click="save"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "@/mixins/validationMixin";

export default {
  props: ["studentDoc", "AddEditdialog", "type"],
  mixins: [validationMixin],

  data() {
    return {
      loading: false,
      valid: false,
      item: {},
    };
  },
  methods: {
    close(afterSave = false) {
      this.$emit("closeDialog", afterSave);
    },
    save() {
      if (this.valid) {
        if (this.type == "edit") {
          this.$http
            .post(
              this.getApiUrl + "/students/updateDocuments/" + this.item.id,
              this.item,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                  //the token is a variable which holds the token
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == false) {
                this.close(true);
              }
            });
        } else {
          this.$http
            .post(this.getApiUrl + "/students/saveDocuments/", this.item, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            })
            .then((response) => {
              if (response.data.status.error == false) {
                this.close(true);
              }
            });
        }
      } else {
        this.validate();
      }
    },
  },
  mounted() {
    this.item = Object.assign({}, this.studentDoc);
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
</style>
